import {Form, Select} from 'antd';
import {useQuery} from 'react-query';
import api from '../../api';

const ReportSearch = ({searchParams = {}, setSearchParams}) => {

  const [form] = Form.useForm();

  const {data: {data: meetings = []} = {}} = useQuery(
    ['search-meeting'],
    () => {
      return api.meetings.meetingList();
    }, {
      cacheTime: Infinity,
      onSuccess: ({data}) => {
        const {id: value} = data[0];
        setSearchParams({meeting: value});
        form.setFieldsValue({
          meeting: data[0].id
        });
      }
    });

  const {data: {data: specialPrograms = []} = {}} = useQuery(
    ['search-special_program', searchParams.meeting],
    () => {
      return api.meetings.meetingSpecialProgramList({meeting: searchParams.meeting});
    }, {
      cacheTime: Infinity,
      enabled: !!searchParams.meeting,
    });

  const {data: {data: accompanyPrograms = []} = {}} = useQuery(
    ['search-accompany_program', searchParams.meeting],
    () => {
      return api.meetings.meetingAccompanyProgramList({meeting: searchParams.meeting});
    }, {
      cacheTime: Infinity,
      enabled: !!searchParams.meeting,
    });

  const {data: {data: eveningPrograms = []} = {}} = useQuery(
    ['search-evening_program', searchParams.meeting],
    () => {
      return api.meetings.meetingEveningProgramList({meeting: searchParams.meeting});
    }, {
      cacheTime: Infinity,
      enabled: !!searchParams.meeting,
    });

  const {data: {data: parkingPasses = []} = {}} = useQuery(
    ['search-parking_pass', searchParams.meeting],
    () => {
      return api.meetings.meetingParkingPassList({meeting: searchParams.meeting});
    }, {
      cacheTime: Infinity,
      enabled: !!searchParams.meeting,
    });
    
  const paramChangeHandler = (param) => (value) => {
    if (param === 'meeting') {
      setSearchParams({[param]: value});
      form.resetFields(['specialProgram']);
      form.resetFields(['accompanyProgram']);
      form.resetFields(['eveningProgram']);
      form.resetFields(['parkingPass']);
    } else {
      setSearchParams({...searchParams, [param]: value});
    }
    return undefined;
  }

  return <Form
    form={form}
    name="search"
    labelCol={{span: 4}}
    wrapperCol={{span: 18}}
  >
    <Form.Item label="Tagung" name="meeting">
      <Select
        style={{width: '100%'}}
        value={searchParams && searchParams.meeting}
        onChange={paramChangeHandler('meeting')}
      >
        {meetings.map(item => <Select.Option key={item.id} value={item.id}>
          {item.name}
        </Select.Option>)}
      </Select>
    </Form.Item>

    { specialPrograms.length > 0 && <Form.Item label="Fachprogramm" name="specialProgram">
      <Select
        style={{width: '100%'}}
        value={searchParams && searchParams.specialProgram}
        onChange={paramChangeHandler('specialProgram')}
        allowClear={true}
      >
        {specialPrograms.map(item => <Select.Option key={item.id} value={item.id}>
          {item.name}
        </Select.Option>)}
      </Select>
    </Form.Item> }

    { eveningPrograms.length > 0 && <Form.Item label="Abendprogramm" name="eveningProgram">
      <Select
        style={{width: '100%'}}
        value={searchParams && searchParams.specialProgram}
        onChange={paramChangeHandler('eveningProgram')}
        allowClear={true}
      >
        {eveningPrograms.map(item => <Select.Option key={item.id} value={item.id}>
          {item.name}
        </Select.Option>)}
      </Select>
    </Form.Item> }

    { accompanyPrograms.length > 0 && <Form.Item label="Begleitprogramm" name="accompanyProgram">
      <Select
        style={{width: '100%'}}
        value={searchParams && searchParams.specialProgram}
        onChange={paramChangeHandler('accompanyProgram')}
        allowClear={true}
      >
        {accompanyPrograms.map(item => <Select.Option key={item.id} value={item.id}>
          {item.name}
        </Select.Option>)}
      </Select>
    </Form.Item> 
    
    }
    { parkingPasses.length > 0 && <Form.Item label="Parkausweis" name="parkingPass">
      <Select
        style={{width: '100%'}}
        value={searchParams && searchParams.parkingPass}
        onChange={paramChangeHandler('parkingPass')}
        allowClear={true}
      >
        {parkingPasses.map(item => <Select.Option key={item.id} value={item.id}>
          {item.name}
        </Select.Option>)}
      </Select>
    </Form.Item> 
    
    }
  </Form>;
}

export default ReportSearch;