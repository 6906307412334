import {
    formatDate,
    normalizeDateFields,
    normalizeOutDateFields
} from '../../utils/normalize';
import CRUD from '../../components/CRUD';
import api from '../../api';
import MeetingParkingPassForm from './MeetingParkingPassForm';


const MeetingParkingPassList = ({parentId, parentName}) => {
    const columns = [
        {
            title: 'Datum',
            dataIndex: 'date_program',
            key: 'date_program',
            render: (text => {
               return formatDate(text)
            }),
            sorter: (a, b) => a.date_program > b.date_program,
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => a.name > b.name,
        },
    ];

    const normalizeFormData = (data) => {
        if (data['id']) data['set_statuses'] = data['statuses'];
        return normalizeDateFields(data, ['date_program']);
    }

    const normalizeSendData = (data) => {
        return normalizeOutDateFields(data, ['date_program']);
    }

    return <CRUD
        keyName="meeting-parking-pass"
        title="Parkausweis zur Tagung"
        FormComponent={MeetingParkingPassForm}
        tableColumns={columns}
        apiList={api.meetings.meetingParkingPassList}
        apiRetrieve={api.meetings.meetingParkingPassRetrieve}
        apiCreate={api.meetings.meetingParkingPassCreate}
        apiUpdate={api.meetings.meetingParkingPassUpdate}
        apiDelete={api.meetings.meetingParkingPassDelete}
        itemValueName="name"
        itemName="parkausweis"
        parentField="meeting"
        parentId={parentId}
        parentName={parentName}
        normalizeFormData={normalizeFormData}
        normalizeSendData={normalizeSendData}
    />
};

export default MeetingParkingPassList;
