import CRUD from '../../components/CRUD';
import api from '../../api';
import MemberStatusForm from './MemberStatusForm';

const MemberStatusList = () => {
    const columns = [
        {
            title: 'Statusname',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => a.name > b.name,
        },
        {
            title: 'Ordnung',
            dataIndex: 'order',
            key: 'order',
            sorter: (a, b) => a.order < b.order,
        },
        {
            title: 'Ist aktiv',
            dataIndex: 'is_visible',
            key: 'is_b',
            width: '20%',
            render: (text) => (
                text ? 'Ja' : 'Nein'
            ),
            sorter: (a, b) => a.is_visible - b.is_visible,
        },
        {
            title: 'Rechnungen',
            dataIndex: 'is_invoice',
            key: 'is_b',
            width: '20%',
            render: (text) => (
                text ? 'Ja' : 'Nein'
            ),
            sorter: (a, b) => a.is_invoice - b.is_invoice,
        },
    ];

    return <CRUD
        keyName="member-status"
        title="Teilnehmerstatus"
        itemName="status"
        FormComponent={MemberStatusForm}
        tableColumns={columns}
        apiList={api.meetings.statusList}
        apiRetrieve={api.meetings.statusRetrieve}
        apiCreate={api.meetings.statusCreate}
        apiUpdate={api.meetings.statusUpdate}
        apiDelete={api.meetings.statusDelete}
    />
};

export default MemberStatusList;
