import {DatePicker, Form, Input, InputNumber, Space} from 'antd';
import {useQuery} from "react-query";
import api from "../../api";

const MeetingParkingPassForm = ({form, onFinish}) => {
  const {data: {data: statusList = []} = {}} = useQuery(['statuses'],
  async () => {
    return await api.meetings.statusList();
  }, {
    cacheTime: Infinity,
  });
  return (
    <Form
      form={form}
      name="meetingEveningProgram"
      labelCol={{span: 6}}
      wrapperCol={{span: 18}}
      layout="horizontal"
      onFinish={onFinish}
    >
      <Form.Item label="Name" name="name" rules={[{required: true}]}>
        <Input/>
      </Form.Item>
      <Form.Item label="Datum" name="date_program" rules={[{required: true}]}>
        <DatePicker format="DD.MM.YYYY"/>
      </Form.Item>
      <Form.Item label="Kosten">
      <Form.List name="set_statuses">
        {fields => (<div>
          {statusList.map((item, index) => {
              return <Space key={index}>
                <Form.Item key={`status_${item.id}`} hidden initialValue={item.id}
                           name={[index, 'status']}><Input/></Form.Item>
                <Form.Item label={item.name} key={`price_${item.id}`} initialValue={0} rules={[{required: true}]}
                           name={[index, 'price']}><InputNumber addonAfter="€" style={{width:100}}/></Form.Item>
                 <Form.Item label='Rabattpreis' key={`discount_price_${item.id}`} initialValue={0} rules={[{required: true}]}
                           name={[index, 'discount_price']}><InputNumber addonAfter="€" style={{width:100}}/></Form.Item>
              </Space>
            }
          )}
        </div>)}
      </Form.List>
      </Form.Item>
    </Form>
  );
};

export default MeetingParkingPassForm;
