import api from '../../api';
import CRUD from '../../components/CRUD';
import {
    formatDate,
    normalizeDateFields,
    normalizeOutDateFields
} from '../../utils/normalize';
import MeetingSpecialProgramForm from './MeetingSpecialProgramForm';

const MeetingSpecialProgramList = ({parentId, parentName}) => {
    const columns = [
        {
            title: 'Datum',
            dataIndex: 'date_program',
            key: 'date_program',
            render: (text => {
               return formatDate(text)
            }),
            sorter: (a, b) => a.date_program > b.date_program,
        },
        {
            title: 'Fachprogramm',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => a.name > b.name,
        },
        {
            title: 'Tageszeit',
            dataIndex: 'times_of_day_name',
            key: 'times_of_day_name'
        },
        {
            title: 'Alternativ zu',
            dataIndex: 'parent_name',
            key: 'parent',
            sorter: (a, b) => a.parent > b.parent,
        },
        {
            title: 'Maximale Teilnehmerzahl',
            dataIndex: 'max_members',
            key: 'max_members',
        },
    ];

    const normalizeFormData = (data) => {
        if (data['id']) data['set_statuses'] = data['statuses'];
        return normalizeDateFields(data, ['date_program']);
    }

    const normalizeSendData = (data) => {
        return normalizeOutDateFields(data, ['date_program']);
    }

    return <CRUD
        keyName="meeting-special-program"
        title="Liste Tagungsorte zur Tagung"
        FormComponent={MeetingSpecialProgramForm}
        tableColumns={columns}
        apiList={api.meetings.meetingSpecialProgramList}
        apiRetrieve={api.meetings.meetingSpecialProgramRetrieve}
        apiCreate={api.meetings.meetingSpecialProgramCreate}
        apiUpdate={api.meetings.meetingSpecialProgramUpdate}
        apiDelete={api.meetings.meetingSpecialProgramDelete}
        itemValueName="name"
        itemName="fachprogramme"
        parentField="meeting"
        parentId={parentId}
        parentName={parentName}
        normalizeFormData={normalizeFormData}
        normalizeSendData={normalizeSendData}
    />
};

export default MeetingSpecialProgramList;
