import {DatePicker, Form, Input, InputNumber, Select, Space, Switch} from 'antd';
import {useEffect, useState} from 'react';
import {useQuery} from 'react-query';

import api from '../../api';
import {timesOfDay} from '../../constants';
import {queryClient} from '../../utils/react-query';

const {Option} = Select;

const MeetingSpecialProgramForm = (
  {form, onFinish, itemData, parentFieldParamName, parentId}) => {

  const listParams = {};
  listParams[parentFieldParamName] = parentId;
  if (itemData.id) {
    listParams['parent__not'] = itemData.id;
  }

  const {data: {data: statusList = []} = {}} = useQuery(['statuses'],
    async () => {
      return await api.meetings.statusList();
    }, {
      cacheTime: Infinity,
    });

  useEffect(
    () => queryClient.invalidateQueries('spec_prog_parents'),
    []
  );

  const {data: {data: parents = []} = {}} = useQuery(['spec_prog_parents', listParams],
    () => {
      return api.meetings.meetingSpecialProgramSelectList(listParams);
    }, {
      cacheTime: Infinity,
    });

      console.log(itemData)

  return (
    <Form
      form={form}
      name="meetingSpecialProgram"
      labelCol={{span: 6}}
      wrapperCol={{span: 18}}
      layout="horizontal"
      onFinish={onFinish}
    >
      <Form.Item label="Fachprogramm" name="name" rules={[{required: true}]}>
        <Input/>
      </Form.Item>
      <Form.Item label="Untertitel" name="subtitle">
        <Input/>
      </Form.Item>
      <Form.Item label="Datum" name="date_program" rules={[{required: true}]}>
        <DatePicker format="DD.MM.YYYY"/>
      </Form.Item>
      <Form.Item label="Tageszeit" name="times_of_day">
        <Select allowClear>
          {timesOfDay.map(item => <Option value={item.value}>{item.label}</Option> )}
        </Select>
      </Form.Item>
      <Form.Item label="Auswahl Teilnehmer" valuePropName="checked"
                 name="selection_of_members" initialValue={false}>
        <Switch/>
      </Form.Item>
      <Form.Item label="Alternativ zu" name="parent">
        <Select allowClear>
          {
            parents.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)
          }
        </Select>
      </Form.Item>
      <Form.Item label="Kosten">
      <Form.List name="set_statuses">
        {fields => (<div>
          {statusList.map((item, index) => {
              return <Space key={index}>
                <Form.Item key={`status_${item.id}`} hidden initialValue={item.id}
                           name={[index, 'status']}><Input/></Form.Item>
                <Form.Item label={item.name} key={`price_${item.id}`} initialValue={0} rules={[{required: true}]}
                           name={[index, 'price']}><InputNumber addonAfter="€" style={{width:100}}/></Form.Item>
                 <Form.Item label='Rabattpreis' key={`discount_price_${item.id}`} initialValue={0} rules={[{required: true}]}
                           name={[index, 'discount_price']}><InputNumber addonAfter="€" style={{width:100}}/></Form.Item>
              </Space>
            }
          )}
        </div>)}
      </Form.List>
      </Form.Item>
      <Form.Item label="Begleitperson" valuePropName="checked"
                 name="is_allow_for_partner" initialValue={true}>
        <Switch/>
      </Form.Item>
      <Form.Item label="Maximale Teilnehmerzahl" name="max_members">
        <InputNumber/>
      </Form.Item>
      <Form.Item label='Sortierung' initialValue={0} rules={[{required: true}]}
                 name="sort_num"><InputNumber/>
      </Form.Item>
    </Form>
  );
};

export default MeetingSpecialProgramForm;
