import {DatePicker, Form, Input, InputNumber, Space, Switch, Select} from 'antd';
import RichEditor from '../../components/RichEditor';
import {useQuery} from 'react-query';

import api from '../../api';
import {onValuesChange} from '../../utils';
import { useState } from 'react';

const MeetingControlForm = ({form, onFinish, itemData}) => {

  console.log(itemData)

  var [statusId, setStatusId] = useState(null);

  const {data: {data: statusList = []} = {}} = useQuery(['statuses'],
    async () => {
      return await api.meetings.statusList();
    }, {
      cacheTime: Infinity,
    });

  return (
    <Form
      form={form}
      name="MeetingControlForm"
      labelCol={{span: 8}}
      wrapperCol={{span: 16}}
      layout="horizontal"
      onFinish={onFinish}
      onValuesChange={onValuesChange(form)}
    >
      <Form.Item label="Tagung" name="name" rules={[{required: true}]}><Input/></Form.Item>
      <Form.Item label="Bezeichnung in der Rechnung" rules={[{required: true}]}
                 name="full_name"><Input/></Form.Item>
      <Form.Item label="Kurzbezeichnung Namensschilder" rules={[{required: true}]}
                 name="short_name"><Input/></Form.Item>

      <Form.Item label="Kosten">
      <Form.List name="set_statuses">
        {fields => (<div>
          {statusList.map((item, index) => {
              return <Space key={index}>
                <Form.Item key={`status_${item.id}`} hidden initialValue={item.id}
                           name={[index, 'status']}><Input/></Form.Item>
                <Form.Item label={item.name} key={`price_${item.id}`} initialValue={0} rules={[{required: true}]}
                           name={[index, 'price']}><InputNumber addonAfter="€" style={{width:100}}/></Form.Item>
              </Space>
            }
          )}
        </div>)}
      </Form.List>
      </Form.Item>

      <Form.Item label="Anmeldeschluss" name="registration_latest_date" rules={[{required: true}]}>
        <DatePicker format="DD.MM.YYYY"/></Form.Item>
      <Form.Item label="Begleitperson" valuePropName="checked" name="companion"
                 initialValue={false}>
        <Switch/>
      </Form.Item>

      <p>Bei mehreren Tagungsorten/Terminen diese Felder leer lassen und dafür
        Tagungsorte über Liste hinzufügen.</p>

      <Form.Item label="Datum von" style={{marginBottom: 0}}>
        <Form.Item name="date_from" style={{display: 'inline-block'}}>
          <DatePicker format="DD.MM.YYYY"/>
        </Form.Item>
        <span className="inline-form-label">bis</span>
        <Form.Item style={{display: 'inline-block'}} name="date_to">
          <DatePicker format="DD.MM.YYYY"/>
        </Form.Item>
      </Form.Item>

      <Form.Item label="Zeit:" style={{marginBottom: 0}}>
        <Form.Item name="time" style={{display: 'inline-block', width: 'calc(50%)'}}>
          <Input/>
        </Form.Item>
      </Form.Item>

      <Form.Item label="Räumlichkeiten" name="place"><Input/></Form.Item>

      <Form.Item label="Straße" style={{marginBottom: 0}}>
        <Form.Item name="street" style={{display: 'inline-block'}}>
          <Input/>
        </Form.Item>
        <span style={{
          display: 'inline-block',
          width: '70px',
          lineHeight: '32px',
          textAlign: 'center'
        }}>PLZ/Ort:</span>
        <Form.Item style={{display: 'inline-block', width: 85}} name="post_index">
          <Input/>
        </Form.Item>
        <Form.Item style={{display: 'inline-block'}} name="area">
          <Input/>
        </Form.Item>
      </Form.Item>

      <Form.Item label="pdf-Dateiname" name="confirmation_pdf_filename"><Input/></Form.Item>
      
      <Form.Item label="Ist aktiv" valuePropName="checked" name="is_visible"
                 initialValue={true}>
        <Switch/>
      </Form.Item>

      <Form.Item label="Status Beschreibung">
      <Select onChange={(value) => {
        setStatusId(value);
      }}>
          {statusList.map((item, index) => {
              return <Select.Option value={item.id}>{item.name}</Select.Option>  
            }
          )}
      </Select>
      </Form.Item>
      <Form.List name="set_desc_statuses">
        {fields => (<div>
          {statusList.map((item, index) => {
              return statusId == item.id && <div key={index}>
                <Form.Item key={`status_${item.id}`} hidden initialValue={item.id}
                           name={[index, 'status']}><Input/></Form.Item>
                <Form.Item key={`special_program_description_${item.id}`} label="Fachprogramm Titel"
                      name={[index, 'special_program_description']} rules={[{required: false}]}>
                <RichEditor type='' height={300} initialValue={itemData.desc_statuses && itemData.desc_statuses[index].special_program_description}/>
                </Form.Item>
                <Form.Item key={`accompany_program_description_${item.id}`} label="Begleitprogramm Titel"
                      name={[index, 'accompany_program_description']} rules={[{required: false}]}>
                <RichEditor type='' height={300} initialValue={itemData.desc_statuses && itemData.desc_statuses[index].accompany_program_description}/>
                </Form.Item>
                <Form.Item key={`evening_program_description_${item.id}`} label="Abendprogramm Titel"
                      name={[index, 'evening_program_description']} rules={[{required: false}]}>
                <RichEditor type='' height={300} initialValue={itemData.desc_statuses && itemData.desc_statuses[index].evening_program_description}/>
                </Form.Item>
                <Form.Item key={`parking_pass_description_${item.id}`} label="Parkausweis Titel"
                      name={[index, 'parking_pass_description']} rules={[{required: false}]}>
                <RichEditor type='' height={300} initialValue={itemData.desc_statuses && itemData.desc_statuses[index].parking_pass_description}/>
                </Form.Item>
              </div>
              }
          )}
        </div>)}
      </Form.List>
      {itemData.id && <>
        <hr className="hr"/>
        <h3>URL:</h3>
        <p>
          {statusList.map((item, index) => {
            const url = `${process.env.REACT_APP_SITE_URL}/registration/${itemData.id}/${item.id}/`
            return <><a href={url} key={index} target="_blank" rel="noreferrer">{item.name}: ({url})</a><br/></>
          })}
        </p>
      </>}
    </Form>
  );
};

export default MeetingControlForm;
